import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";
import _ from "underscore";
import search from "./modules/search";
import Hashids from "hashids";
import enquete from "./modules/enquete";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      key: "sAISearchWindow"
    })
  ],
  state: {
    userId: new Hashids(`${Math.random()}`, 16).encode(new Date().getTime()),
    resources: undefined,
    constObj: undefined
  },
  mutations: {
    setResources(state, payload) {
      state.resources = payload.resources;
    },
    setConstObj(state, payload) {
      state.constObj = payload.constObj;
    },
    setDefaultMessage(state, payload) {
      state.defaultMessage = payload.defaultMessage;
    },
    setYokuaru(state, payload) {
      state.yokuaru = payload.yokuaru;
    }
  },
  actions: {
    async initChatWindow({ commit, state, dispatch }) {
      console.log("v3.3");
      await dispatch("fetchResources");
      await dispatch("fetchDefaultMessage");
      await dispatch("fetchYokuaru");
      const { FunctionConst, MessageConst } = CONSTS;
      const obj = Object.assign(
        {},
        MessageConst,
        FunctionConst,
        state.defaultMessage
      );
      commit("setConstObj", { constObj: obj });
      commit("setSearchingMessage", ""); //, { root: true })
      if (this.getters.isInitialState) {
        dispatch("dispatchAction");
      }
    },

    async fetchResources({ commit }) {
      var digits = 4;
      var c = "0123456789";
      var cl = c.length;
      var r = "";
      for (var i = 0; i < digits; i++) {
        r += c[Math.floor(Math.random() * cl)];
      }
      const resourcePath = `${ENV_SETTINGS.resourceFileUrl}?v=${r}`;
      try {
        const result = await axios.get(resourcePath);
        if (result.status !== 200) {
          console.log("Error!!");
          process.exit();
        }
        commit("setResources", { resources: result.data });
      } catch (e) {
        console.log("Error!!");
        process.exit();
      }
    },

    async fetchDefaultMessage({ commit }) {
      var digits = 4;
      var c = "0123456789";
      var cl = c.length;
      var r = "";
      for (var i = 0; i < digits; i++) {
        r += c[Math.floor(Math.random() * cl)];
      }
      const defaultMessagePath = `${ENV_SETTINGS.defaultMessageUrl}?v=${r}`;
      try {
        const result = await axios.get(defaultMessagePath);
        commit("setDefaultMessage", { defaultMessage: result.data });
      } catch (e) {
        console.log("default message not found");
      }
    },

    async fetchYokuaru({ commit }) {
      var digits = 4;
      var c = "0123456789";
      var cl = c.length;
      var r = "";
      for (var i = 0; i < digits; i++) {
        r += c[Math.floor(Math.random() * cl)];
      }
      const yokuaruPath = `${ENV_SETTINGS.yokuaruUrl}?v=${r}`;
      try {
        const result = await axios.get(yokuaruPath);
        commit("setYokuaru", { yokuaru: result.data });
      } catch (e) {
        console.log("yokuaru not found");
      }
    }
  },
  modules: {
    search: search,
    enquete: enquete
  }
});
