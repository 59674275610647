var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showFaqTitle
    ? _c("div", { staticClass: "saichat-suggestion", style: _vm.style }, [
        _c(
          "ul",
          [
            _vm.showFaqTitle
              ? _c(
                  "li",
                  {
                    staticClass:
                      "autocompleteCaption saichat-suggestion__choice"
                  },
                  [
                    _vm.isYokuaru
                      ? _c("a", { staticClass: "saichat-suggestion__title" }, [
                          _vm._v(_vm._s(_vm.yokuaruTitle))
                        ])
                      : _c("a", { staticClass: "saichat-suggestion__title" }, [
                          _vm._v(_vm._s(_vm.choicesTitle))
                        ]),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "sci-ion-ios-close",
                      on: { click: _vm.close }
                    })
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.userInputChoices, function(choice, i) {
              return _c(
                "li",
                { key: i, staticClass: "saichat-suggestion__choice" },
                [
                  choice
                    ? _c(
                        "a",
                        {
                          staticClass: "saichat-suggestion__choice__link",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.selectAnswer(choice, i)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            _vm._l(choice.ancesters, function(ancester, index) {
                              return _c(
                                "span",
                                { key: index, staticClass: "ancester" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(ancester.text) +
                                      "\n            "
                                  ),
                                  index < choice.ancesters.length - 1
                                    ? _c("span", [_vm._v(">")])
                                    : _vm._e()
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("span", {
                            staticClass:
                              "saichat-suggestion__choice__link__content",
                            domProps: {
                              innerHTML: _vm._s(
                                choice.displayText || choice.text
                              )
                            }
                          })
                        ]
                      )
                    : _vm._e()
                ]
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        !_vm.isYokuaru
          ? _c("div", { staticClass: "saichat-suggestion__other" }, [
              _vm.userInputChoices.length && _vm.hasUnresolvedEnquete
                ? _c(
                    "a",
                    {
                      staticClass: "saichat-suggestion__other__choice",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.openEnquete(_vm.searchFailed)
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "saichat-suggestion__other__icon",
                        attrs: { src: require("@product/resource/enquete.svg") }
                      }),
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.otherChoicesLinkMessage) +
                          "\n    "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.userInputChoices.length
                ? _c(
                    "span",
                    {
                      staticClass: "saichat-suggestion__other__scriptNotFound"
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.scriptNotFoundResultsMessage) +
                          "\n      "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _vm.hasUnresolvedEnquete
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "saichat-suggestionother__scriptNotFound__button",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.openEnquete(_vm.searchNoScript)
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "saichat-suggestion__other__icon",
                                attrs: {
                                  src: require("@product/resource/enquete.svg")
                                }
                              }),
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.scriptNotFoundResultsLinkMessage) +
                                  "\n      "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }