var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "rel" }, [
      _c("div", { staticClass: "navigationSummary" }, [
        _c("div", { staticClass: "rel" }, [
          _c(
            "div",
            {
              staticClass: "navigationSummaryContentWrapper scrollX",
              attrs: { id: "sci-bread" }
            },
            [
              _c(
                "div",
                { staticClass: "bread navigationSummaryContent" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "ib span navigationSummaryItemTop",
                      class: { selected: 0 == _vm.index },
                      on: {
                        click: function($event) {
                          return _vm.selectRoute(_vm.bread[0], 0)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.constObj.BREAD_LIST_TOP_TITLE))]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.bread, function(b, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        staticClass: "navigationSummaryItem ib span",
                        class: { selected: i == _vm.index, second: i == 1 },
                        style: {
                          "z-index": 1000 - i,
                          position: i == 0 ? "sticky !important" : "relative",
                          display: i == 0 ? "none !important" : "inline-block"
                        },
                        on: {
                          click: function($event) {
                            return _vm.selectRoute(b, i)
                          }
                        }
                      },
                      [
                        i == 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "navigationSummaryItemTop ib span navigationSummaryItem__text"
                              },
                              [_vm._v("TOP")]
                            )
                          : "text" in b
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "ib span navigationSummaryItem__text",
                                  style: {
                                    "margin-left": i == 1 ? "30px" : "0px"
                                  }
                                },
                                [_vm._v(_vm._s(b.text))]
                              )
                            : _vm._e()
                      ]
                    )
                  })
                ],
                2
              )
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }