var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "saichat-user-input", style: _vm.styleField },
    [
      _c("div", { staticClass: "saichat-user-input__form" }, [
        _c("input", {
          ref: "sendMessageInput",
          staticClass: "saichat-user-input__form__field",
          attrs: {
            type: "text",
            placeholder: _vm.constObj.DEFAULT_INPUT_MESSAGE
          },
          domProps: { value: _vm.inputMessage },
          on: {
            input: [
              function($event) {
                _vm.inputMessage = $event.target.value
              },
              _vm.onInput
            ],
            click: function($event) {
              $event.preventDefault()
              return _vm.onFocusInput($event)
            },
            compositionstart: _vm.onConpositionStart,
            compositionend: _vm.onCompositionend
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }