import Vue from "vue";
import Component from "vue-class-component";

import { eventBus } from "@/eventBus";

@Component({
  props: {
    isIphone: Boolean,
    isSP: Boolean
  }
})
export default class UserInputMixin extends Vue {
  inputMessage = "";
  onIME = false;
  styleField = {
    paddingBottom: ""
  };
  styleSend = {
    pointerEvents: ""
  };

  get constObj() {
    return this.$store.state.constObj;
  }

  sendMessage() {
    if (this.inputMessage && !this.onIME) {
      this.$emit("sendMessage", this.inputMessage);
      this.clearInputMessage();
    } else {
      this.$refs.sendMessageInput.focus();
      this.onFocusInput();
    }
  }
  clearInputMessage() {
    this.inputMessage = "";
  }
  onFocusInput() {
    if (this.onIME) {
      this.$emit("onChangeInputMessage", {
        text: this.inputMessage,
        type: "focus"
      });
      this.$emit("updateIsFocusMessageInput", false);
      this.onIME = false;
      return;
    }
    this.$emit("onChangeInputMessage", {
      text: this.inputMessage,
      type: "focus"
    });
    this.$emit("updateIsFocusMessageInput", true);
  }
  onConpositionStart() {
    if (this.isIphone) {
      this.styleSend.pointerEvents = "none";
    }
    this.onIME = true;
  }
  onCompositionend() {
    this.$emit("onChangeInputMessage", {
      text: this.inputMessage,
      type: "enter"
    });
    this.onIME = false;
    this.styleSend.pointerEvents = "";
  }

  onInput(e) {
    let type = "keyInput";
    this.$emit("onChangeInputMessage", { text: this.inputMessage, type });
  }

  created() {
    eventBus.$off("clearInputMessage");
    eventBus.$on("clearInputMessage", () => {
      this.clearInputMessage();
    });
  }
}
