import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { eventBus } from "@/eventBus";
import base64url from "base64url";
import { scrollIntoViewX } from "@/libs/scrollController";

@Component({
  props: {
    bread: Array,
    index: Number,
    routingMode: String,
    inputMessage: String,
    userArgentData: Object
  }
})
export default class BreadListMixin extends Vue {
  functionConst = CONSTS.FunctionConst;

  get constObj() {
    return this.$store.state.constObj;
  }

  getParamsFromBreadCrumbs(stepId, index) {
    let talkScriptId = "";
    let stepIdList = [];
    this.bread.slice(0, index + 1).forEach(breadCrumb => {
      if (breadCrumb.talkScriptId) {
        talkScriptId = breadCrumb.talkScriptId;
      }
      if (breadCrumb.stepId) {
        stepIdList.push(breadCrumb.stepId);
      }
    });
    if (stepIdList.slice(-1)[0] != stepId) {
      stepIdList.push(stepId);
    }
    return {
      talkScriptId,
      stepEncoded: base64url.encode(JSON.stringify(stepIdList))
    };
  }

  selectRoute(choice, i) {
    eventBus.$emit("setIndex", Math.max(0, i));

    const selectedElement = document.querySelector(
      ".navigationSummaryItem.selected"
    );
    if (selectedElement) {
      scrollIntoViewX(selectedElement, "center");
    }
    if (
      this.routingMode != this.functionConst.DISPLAYING_ROUTE_MODE.URL_BASED
    ) {
      return;
    }

    const result = {
      name: choice.talkScriptId == "#" ? "root" : "urlWithParams",
      params: choice.stepId
        ? this.getParamsFromBreadCrumbs(choice.stepId, i)
        : { talkScriptId: choice.talkScriptId }
    };
    this.$router.push(result);
    if (i === 0 && this.index !== i) {
      const additionalTicketParams = {};
      if (this.inputMessage && this.inputMessage.length > 0) {
        additionalTicketParams.query = this.inputMessage;
      }
      this.postTicket("quitAction", additionalTicketParams);
    }
  }

  async postTicket(action, params) {
    Object.assign(params, {
      device: this.userArgentData.device.type || "PC",
      userId: this.$store.state.userId,
      osName: this.userArgentData.os.name,
      browserName: this.userArgentData.browser.name
    });

    const ticketOrder = {
      action
    };
    await this.$ticket.setData(params);
    this.$ticket.handleTicket(ticketOrder);
  }

  @Watch("bread")
  onBreadCrumbChanged() {
    setTimeout(() => {
      document.getElementById("sci-bread").scrollLeft = 99999;
    }, 500);
  }
}
