import Vue from "vue";
import Router from "vue-router";
import Container from "@/components/Container";

Vue.use(Router);
const functionConst = CONSTS.FunctionConst;
const routingMode = functionConst.ROUTING_MODE;
const base = "/";
let mode;
let routes;
switch (routingMode) {
  case functionConst.DISPLAYING_ROUTE_MODE.URL_BASED:
    mode = "hash";
    routes = [
      {
        path: "/",
        name: "root",
        component: Container,
        props: route => ({
          query: route.query
        }),
        meta: { isFromRoot: true }
      },
      {
        path: "/:talkScriptId?/:stepEncoded?",
        name: "urlWithParams",
        component: Container,
        props: route => ({
          query: route.query
        }),
        beforeEnter: (to, from, next) => {
          from.meta.isFromRoot ? next() : next("/");
        }
      }
    ];
    break;
  case functionConst.DISPLAYING_ROUTE_MODE.HASH:
    mode = "hash";
    routes = [
      {
        path: "*",
        component: Container,
        props: route => ({
          query: route.query
        })
      }
    ];
    break;
  case functionConst.DISPLAYING_ROUTE_MODE.HISTORY:
    mode = "history";
    routes = [
      {
        path: "*",
        component: Container,
        props: route => ({
          query: route.query
        })
      }
    ];
    break;
}

export default new Router({
  mode: mode,
  routes: routes,
  base: base
});
