var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "saichat-header" }, [
    _c(
      "a",
      {
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.toggleWindow($event)
          }
        }
      },
      [
        _vm.functionConst.MINIMIZE.type == "icon"
          ? _c(
              "span",
              {
                staticClass:
                  "saichat-header__btn saichat-header__btn__icon-minimize-background"
              },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: _vm.functionConst.MINIMIZE.src }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.functionConst.MINIMIZE.type == "text"
          ? _c(
              "span",
              {
                staticClass:
                  "saichat-header__btn saichat-header__btn__text-minimize-background"
              },
              [_c("span", [_vm._v(_vm._s(_vm.functionConst.MINIMIZE.src[0]))])]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.functionConst.MINIMIZE.type == "img"
          ? _c(
              "span",
              {
                staticClass:
                  "saichat-header__btn saichat-header__btn__img-minimize-background"
              },
              [
                _c("img", {
                  attrs: {
                    src: _vm.getResourceImg(_vm.functionConst.MINIMIZE.src[0])
                  }
                })
              ]
            )
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "saichat-header__title" }, [
      _vm._v(_vm._s(_vm.constObj.CHAT_WINDOW_TITLE))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }