import Vue from "vue";
import Component from "vue-class-component";

import { eventBus } from "@/eventBus";
import moment from "moment";

const STATUS = {
  RESOLVED: "resolved",
  UNRESOLVED: "unresolved"
};
@Component({
  props: {
    routingMode: String
  }
})
export default class FeedbackMixin extends Vue {
  functionConst = CONSTS.FunctionConst;
  askFeedbackMessage = "";
  resolvedButtonMessage = "";
  unresolvedButtonMessage = "";
  headingResolvedFeedbackMessage = "";
  headingUnresolvedFeedbackMessage = "";
  returnButtonTitle = "";
  unresolvedFeedbackMessage = "";
  unresolvedFeedbackMessageId = "";
  showFeedback = false;
  showFeedbackResolved = false;
  showFeedbackUnresolved = false;
  closeFeedback = true;
  hasResolvedEnquete = "";
  hasUnresolvedEnquete = "";

  init() {
    this.showFeedbackResolved = false;
    this.showFeedbackUnresolved = false;
    setTimeout(() => {
      this.closeFeedback = true;
      this.open();
    }, 30);
  }
  open() {
    this.showFeedback = true;
  }
  close() {
    this.showFeedback = false;
    this.showFeedbackResolved = false;
    this.showFeedbackUnresolved = false;
  }
  resolved() {
    this.showFeedback = false;
    this.closeFeedback = false;
    this.showFeedbackResolved = true;

    const ticketOrder = {
      action: "feedbackAction"
    };
    this.$ticket.setData({
      feedback: STATUS.RESOLVED
    });
    this.$store.state.enquete.enqueteMode = STATUS.RESOLVED;
    this.$ticket.handleTicket(ticketOrder);
    if (this.hasResolvedEnquete) {
      this.$store.state.enquete.isEnqueteOpen = true;
    }
  }
  unresolved() {
    this.feedbackScenarioId = null;
    const items = this.$parent.route[this.$parent.route.length - 1].items;
    if (items && items.log_scenario) {
      this.feedbackScenarioId = items.log_scenario.join(",");
      this.unresolvedFeedbackMessageId = this.unresolvedFeedbackMessageId.replace(
        "SCENARIO_ID",
        this.feedbackScenarioId
      );
    }

    this.setTicketContents();
    this.showFeedback = false;
    this.closeFeedback = false;
    this.showFeedbackUnresolved = true;
    const ticketOrder = {
      action: "feedbackAction"
    };
    this.$ticket.setData({
      feedback: STATUS.UNRESOLVED
    });
    this.$store.state.enquete.enqueteMode = STATUS.UNRESOLVED;
    this.$ticket.handleTicket(ticketOrder);
    if (this.hasUnresolvedEnquete) {
      this.$store.state.enquete.isEnqueteOpen = true;
    }
  }
  returnTop() {
    if (
      this.routingMode == this.functionConst.DISPLAYING_ROUTE_MODE.URL_BASED
    ) {
      eventBus.$emit("selectScriptItem", "#");
      this.$router.push({ name: "root" });
    } else {
      eventBus.$emit("setIndex", 0);
    }
  }

  setConstMessage() {
    const constObj = this.$store.state.constObj;
    this.askFeedbackMessage = constObj.ASK_FEEDBACK_MESSAGE;
    this.resolvedButtonMessage = constObj.ASK_FEEDBACK_CHOICES.RESOLVED;
    this.unresolvedButtonMessage = constObj.ASK_FEEDBACK_CHOICES.UNRESOLVED;
    this.headingResolvedFeedbackMessage =
      constObj.HEADING_FEEDBACK_MESSAGE.RESOLVED;
    this.headingUnresolvedFeedbackMessage =
      constObj.HEADING_FEEDBACK_MESSAGE.UNRESOLVED;
    this.returnButtonTitle = constObj.RETURN_BUTTON_TITLE;
    this.unresolvedFeedbackMessage = constObj.UNRESOLVED_FEEDBACK_MESSAGE;
    this.unresolvedFeedbackMessageId = constObj.UNRESOLVED_FEEDBACK_MESSAGE_ID;
    this.hasResolvedEnquete = constObj.HAS_RESOLVED_ENQUETE;
    this.hasUnresolvedEnquete = constObj.HAS_UNRESOLVED_ENQUETE;
  }
  setTicketContents() {
    var ticketTags = this.unresolvedFeedbackMessage.match(/\[[a-z-]+\]/g);
    var feedbackErrorMessage = this.unresolvedFeedbackMessage;
    var ticket = this.$ticket.ticket;
    var label = {
      'open': "離脱",
      'answered': "回答済み",
      'scriptNotFound': "未収録",
      'searchFailed': "検索失敗",
      're-search': "再検索",
      'quit': "未完了"
    };
    moment.locale("ja");
    var ms = moment.unix(parseInt(ticket.Data.startTime, 10) / 1000);
    var me = moment.unix(parseInt(ticket.Data.endTime, 10) / 1000);
    if(ticketTags) {
      ticketTags.forEach(element => {
        switch (element) {
          case '[user-id]':
            feedbackErrorMessage = feedbackErrorMessage.replace(element, ticket.Data.userId);
            break;

          case '[parent-category]':
            feedbackErrorMessage = feedbackErrorMessage.replace(element, ticket.Data.log_faq_parent_category[ticket.Data.log_faq_parent_category.length - 1]);
            break;

          case '[child-category]':
            feedbackErrorMessage = feedbackErrorMessage.replace(element, ticket.Data.log_faq_child_category[ticket.Data.log_faq_child_category.length - 1]);
            break;

          case '[start-time]':
            feedbackErrorMessage = feedbackErrorMessage.replace(element, ms.format('YYYY/MM/DD HH:mm:ss'));
            break;

          case '[end-time]':
            feedbackErrorMessage = feedbackErrorMessage.replace(element, me.format('YYYY/MM/DD HH:mm:ss'));
            break;

          case '[year]':
            feedbackErrorMessage = feedbackErrorMessage.replace(element, ms.format('YYYY'));
            break;

          case '[month]':
            feedbackErrorMessage = feedbackErrorMessage.replace(element, ms.format('MM'));
            break;

          case '[day]':
            feedbackErrorMessage = feedbackErrorMessage.replace(element, ms.format('DD'));
            break;

          case '[day-of-the-week]':
            feedbackErrorMessage = feedbackErrorMessage.replace(element, ms.format('ddd'));
            break;

          case '[status]':
            feedbackErrorMessage = feedbackErrorMessage.replace(element, label[ticket.Data.status]);
            break;

          case '[faq-id]':
            feedbackErrorMessage = feedbackErrorMessage.replace(element, ticket.Data.log_faq[ticket.Data.log_faq.length - 1]);
            break;

          case '[question]':
            feedbackErrorMessage = feedbackErrorMessage.replace(element, ticket.Data.log_faq_title[ticket.Data.log_faq_title.length - 1]);
            break;

          case '[search-query]':
            feedbackErrorMessage = feedbackErrorMessage.replace(element, ticket.Data.query ? ticket.Data.query : "");
            break;

          case '[log-scenario]':
            feedbackErrorMessage = feedbackErrorMessage.replace(element, ticket.Data.log_scenario[ticket.Data.log_scenario.length - 1]);
            break;
        }
      });
    }
    this.unresolvedFeedbackMessage = feedbackErrorMessage;
  }
  created() {
    setTimeout(() => {
      this.open();
    }, 30);
  }
  mounted() {
    this.setConstMessage();
  }
}
