var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "messagesContent", attrs: { id: "saichat-content" } },
    [
      _c(
        "div",
        [
          _c("sAISearchCategory", {
            attrs: {
              "script-package": _vm.scriptPackage,
              eventHub: _vm.eventHub,
              "column-width": _vm.categoryWidth,
              height: _vm.categoryHeight
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }