// deltaで示した方向について、スクロールできるエレメントを探す
export const getScrollableAnscesterWithDelta = (element, delta) => {
  const selector = ".scrollX,.scrollY";
  for (
    let target = element;
    target;
    target = target.parentElement && target.parentElement.closest(selector)
  ) {
    if (target.classList.contains("scrollX") && delta.x !== 0) {
      // 横スクロールに関しては、propagationしない
      return target;
    } else if (target.classList.contains("scrollY")) {
      // 縦スクロールに関しては、実際にスクロール可能範囲になければ、propagationする
      if (delta.y < 0 && target.scrollTop > 0) {
        return target;
      } else if (
        delta.y > 0 &&
        target.scrollHeight - target.clientHeight > target.scrollTop
      ) {
        return target;
      }
    }
  }
  return window;
};

// directionで示した方向で、スクロールできるエレメントを探す
export const getScrollableAnscester = (element, direction) => {
  const selector = direction
    ? ".scroll" + direction.toUpperCase()
    : ".scrollX,.scrollY";
  const target = element.closest(selector);
  return target ? target : window;
};

const getOffsetTop = (element, scrollableElement) => {
  const scrollOffsetParent = scrollableElement.offsetParent;
  let offsetTop = 0;
  for (
    let target = element;
    target && target !== scrollOffsetParent;
    target = target.offsetParent
  ) {
    offsetTop += target.offsetTop;
  }
  return offsetTop - (scrollOffsetParent ? scrollOffsetParent.offsetTop : 0);
};

export function scrollIntoViewX(element, mode) {
  const scrollElement = getScrollableAnscester(element, "x");
  if (scrollElement === window) {
    console.error("scrollElement not found");
    return;
  }

  const firstElement = element.parentElement.children[0];
  window.scrollElement = scrollElement;
  if (!mode) {
    scrollElement.scrollTo({
      left: element.offsetLeft - firstElement.offsetLeft,
      behavior: "smooth"
    });
  } else if (mode === "center") {
    scrollElement.scrollTo({
      left:
        element.offsetLeft -
        firstElement.offsetLeft +
        element.offsetWidth / 2 -
        scrollElement.clientWidth / 2,
      behavior: "smooth"
    });
  }
}

export function scrollIntoViewY(element, mode) {
  const scrollElement = getScrollableAnscester(element, "y");
  const offsetTop = getOffsetTop(element, scrollElement);
  window.scrollElement = scrollElement;
  if (!mode) {
    scrollElement.scrollTo({
      top: offsetTop,
      behavior: "smooth"
    });
  } else if (mode === "center") {
    scrollElement.scrollTo({
      top:
        offsetTop - element.offsetHeight / 2 - scrollElement.clientHeight / 2,
      behavior: "smooth"
    });
  }
}

export function scrollIntoViewDualY(mainElement, optionalElement) {
  const requiredMainHeight = 430;
  const scrollElement = getScrollableAnscester(mainElement, "y");
  window.scrollElement = scrollElement;
  const optionalHeight = mainElement.offsetTop - optionalElement.offsetTop;
  const scrollElementHeight =
    scrollElement.clientHeight || scrollElement.innerHeight;
  if (scrollElementHeight < requiredMainHeight) {
    scrollElement.scrollTo({
      top: mainElement.offsetTop,
      behavior: "smooth"
    });
  } else if (scrollElementHeight > optionalHeight + requiredMainHeight) {
    scrollElement.scrollTo({
      top: optionalElement.offsetTop,
      behavior: "smooth"
    });
  } else {
    scrollElement.scrollTo({
      top: mainElement.offsetTop + requiredMainHeight - scrollElementHeight,
      behavior: "smooth"
    });
  }
}
