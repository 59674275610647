import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class HeaderMixin extends Vue {
  functionConst = CONSTS.FunctionConst;

  get constObj() {
    return this.$store.state.constObj;
  }

  toggleWindow() {
    this.$emit("toggleWindow");
  }

  getResourceImg(fileName) {
    return require("@product/resource/" + fileName);
  }
}
