var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.closeFeedback
      ? _c(
          "div",
          {
            staticClass: "sci-feedback",
            class: { "sci-feedback-show": _vm.showFeedback }
          },
          [
            _c(
              "a",
              {
                staticClass: "close_button",
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              },
              [_vm._v("×")]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "headingFeedbackMessage" }, [
              _vm._v(_vm._s(_vm.askFeedbackMessage))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resolvedButtonArea" }, [
              _c(
                "button",
                {
                  staticClass: "resolvedButton list-btn",
                  on: {
                    click: function($event) {
                      return _vm.resolved()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.resolvedButtonMessage))]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "resolvedButton list-btn",
                  on: {
                    click: function($event) {
                      return _vm.unresolved()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.unresolvedButtonMessage))]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "bottomBackground" })
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showFeedbackResolved
      ? _c("div", { staticClass: "sci-feedbackResolved" }, [
          _c(
            "a",
            {
              staticClass: "close_button",
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [_vm._v("×")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "headingFeedbackMessage" }, [
            _vm._v(_vm._s(_vm.headingResolvedFeedbackMessage))
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "returnButton list-btn",
              on: {
                click: function($event) {
                  return _vm.returnTop()
                }
              }
            },
            [_vm._v(_vm._s(_vm.returnButtonTitle))]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "bottomBackground" })
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showFeedbackUnresolved
      ? _c(
          "div",
          {
            staticClass: "sci-feedbackUnresolved",
            style: [!_vm.feedbackScenarioId ? { height: 210 + "px" } : {}]
          },
          [
            _c(
              "a",
              {
                staticClass: "close_button",
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              },
              [_vm._v("×")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "headingFeedbackMessage" }, [
              _vm._v(_vm._s(_vm.headingUnresolvedFeedbackMessage))
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "unresolvedFeedbackMessage",
              domProps: { innerHTML: _vm._s(_vm.unresolvedFeedbackMessage) }
            }),
            _vm._v(" "),
            _vm.feedbackScenarioId && _vm.unresolvedFeedbackMessageId
              ? _c("div", { staticClass: "feedbackId" }, [
                  _vm._v(_vm._s(_vm.unresolvedFeedbackMessageId))
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "returnButton list-btn",
                style: [!_vm.feedbackScenarioId ? { top: 15 + "%" } : {}],
                on: {
                  click: function($event) {
                    return _vm.returnTop()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.returnButtonTitle))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "bottomBackground" })
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }