import Vue from "vue";
import Component from "vue-class-component";

import _ from "underscore";
import Header from "@/components/Header";
import Content from "@/components/Content";
import Suggestion from "@/components/Suggestion";
import UserInput from "@/components/UserInput";
import BreadList from "@/components/BreadList";
import Feedback from "@/components/Feedback";
import Enquete from "@/components/Enquete";
import axios from "axios";
import { eventBus } from "@/eventBus";
import { Watch } from "vue-property-decorator";
import base64url from "base64url";
import { uaParser } from "@/libs/uaParser";

@Component({
  components: {
    Header,
    Content,
    Suggestion,
    UserInput,
    BreadList,
    Feedback,
    Enquete
  }
})
export default class ContainerMixin extends Vue {
  functionConst = CONSTS.FunctionConst;
  isReady = false;
  isWindowOpen = "";
  isWindowOpenPc = "";
  isWindowOpenSp = "";
  hasUnresolvedEnquete = "";
  launcherTitle = "";
  headline = "";
  showCopylight = "";
  inputMessage = "";
  isIphone = false;
  userInputChoices = [];
  showFaqTitle = false;
  isFocusMessageInput = false;
  routingMode = "";
  route = [
    {
      componentName: "ResourceList",
      resourceName: "talkScript",
      talkScriptId: "#",
      viewType: "talkScript"
    }
  ];
  index = 0;
  query = "";
  showFeedback = false;
  yokuaru = [];
  isSP = false;
  userArgentData = null;
  customStyle = {
    fontFamily: ""
  };

  get isYokuaru() {
    return this.userInputChoices === this.yokuaru;
  }
  get isSuggestion() {
    return this.isFocusMessageInput;
  }
  get isEnqueteOpen() {
    return this.$store.state.enquete.isEnqueteOpen;
  }
  get enqueteMode() {
    return this.$store.state.enquete.enqueteMode;
  }
  get getEnquetes() {
    return this.$store.state.constObj.ENQUETES;
  }
  get constObj() {
    return this.$store.state.constObj;
  }

  closeSuggestion() {
    this.showFaqTitle = false;
  }
  toggleWindow() {
    this.isWindowOpen = !this.isWindowOpen;
  }
  getResourceImg(fileName) {
    return require("@product/resource/" + fileName);
  }

  setDevice(ua) {
    this.isSP = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      ua
    );
    this.isIphone = /iPhone/i.test(ua);
  }

  setUserArgent(ua) {
    if (!this.userArgentData) {
      this.userArgentData = uaParser.getUa(ua);
    }
  }

  initWindowOpen(isSP) {
    this.isWindowOpen = isSP ? this.isWindowOpenSp : this.isWindowOpenPc;
  }

  async onChangeInputMessage({ text, type }) {
    const additionalTicketParams = {
      log_faq_channel: ["search"]
    };

    if (text && text.length > 0) {
      additionalTicketParams.query = text;
    }

    if (text != "") {
      this.query = text;
      this.userInputChoices = await this.searchAutoComplete(text);
      this.userInputChoices = await this.hilightAutoCompleteText(
        text,
        this.userInputChoices
      );
      this.inputMessage = text;
      if (type === "enter") {
        if (this.userInputChoices.length === 0) {
          this.postTicket("searchNoScriptAction", additionalTicketParams);
        } else {
          this.postTicket("openAction", additionalTicketParams);
        }
      }
      //this.$ticket.setData({ query: text })
    } else {
      if (type === "focus") {
        this.postTicket("openAction", additionalTicketParams);
      }
      this.clearSuggestion();
    }
    this.showFaqTitle = true;
    this.showFeedback = false;
  }

  async postTicket(action, params) {
    Object.assign(params, {
      device: this.userArgentData.device.type || "PC",
      userId: this.$store.state.userId,
      osName: this.userArgentData.os.name,
      browserName: this.userArgentData.browser.name
    });

    const ticketOrder = {
      action,
      params
    };
    //await this.$ticket.setData(params);
    this.$ticket.handleTicket(ticketOrder);
  }

  sendMessage(text) {
    this.clearSuggestion();
    const cleanedText = text.replace(/\n+$/g, "");
  }
  selectAnswer(choice, key) {
    const answer = {
      keyName: _.isString(key) ? key : Number(key) + 1,
      choice: choice
    };
    this.showFaqTitle = false;
  }
  updateIsFocusMessageInput(bool) {
    this.isFocusMessageInput = bool;
  }
  updateInitData() {
    const constObj = this.constObj;
    this.isWindowOpenPc = constObj.IS_WINDOW_OPEN_PC;
    this.isWindowOpenSp = constObj.IS_WINDOW_OPEN_SP;
    this.launcherTitle = constObj.LAUNCHER_TITLE;
    this.customStyle = {
      fontFamily: constObj.CUSTOM_STYLE.FONT_FAMILY
    };
    this.headline = constObj.HEADLINE;
    this.showCopylight = constObj.SHOW_COPYLIGHT;
    this.isReady = true;
    this.routingMode = constObj.ROUTING_MODE;
    this.hasUnresolvedEnquete = constObj.HAS_UNRESOLVED_ENQUETE;
  }
  clearSuggestion() {
    this.inputMessage = "";
    this.userInputChoices = this.yokuaru;
    for (let key of Object.keys(this.userInputChoices)) {
      delete this.userInputChoices[key].displayText;
    }
  }
  updateSuggestionParams(choice, key) {
    const ticketData = {
      results: this.userInputChoices,
      query: this.query
    };
  }

  async initChatWindow() {
    await this.$store.dispatch("initChatWindow");
  }
  async searchAutoComplete(text) {
    return await this.$store.dispatch("searchAutoComplete", text);
  }
  async hilightAutoCompleteText(text, choices) {
    const synonyms = await this.searchSynonym(text);
    let regText = synonyms;
    if (synonyms && synonyms.length) regText = synonyms.join("|");
    for (let key of Object.keys(choices)) {
      const match = choices[key].text.match(new RegExp(regText, "ig"));
      let hilightText = choices[key].text;
      if (regText && match) {
        for (const keyText of match) {
          const hilightKeyText = `<span class='hilight'>${keyText}</span>`;
          hilightText = hilightText.replace(keyText, hilightKeyText);
        }
      }
      this.$set(choices[key], "displayText", hilightText);
    }
    return choices;
  }
  async searchSynonym(text) {
    return await this.$store.dispatch("searchSynonym", text);
  }

  async convertAutoComplete(item) {
    this.yokuaru = await this.$store.dispatch("convertAutoComplete", item);
  }

  getStepEncodedFromRoute(stepId) {
    const routeList = this.route;
    const index = this.index;
    let stepIdList = [];
    for (let route of routeList.slice(0, index + 1)) {
      if (route.stepId) {
        stepIdList.push(route.stepId);
      }
    }
    stepIdList.push(stepId);
    return base64url.encode(JSON.stringify(stepIdList));
  }

  mounted() {
    document.querySelector("body").addEventListener("click", e => {
      if (this.$refs.inputContent) {
        if (!this.$refs.inputContent.contains(e.target)) {
          this.isFocusMessageInput = false;
        }
      }
    });
    window.addEventListener("popstate", () => {
      if (
        this.routingMode != this.functionConst.DISPLAYING_ROUTE_MODE.URL_BASED
      ) {
        return;
      }
      const talkScriptId = this.$route.params.talkScriptId;
      const stepEncoded = this.$route.params.stepEncoded;
      if (!talkScriptId) {
        eventBus.$emit("selectScriptItem", "#");
        this.$router.push({ name: "root" });
        return;
      }
      stepEncoded
        ? eventBus.$emit(
            "selectScenarioItemByStepEncoded",
            talkScriptId,
            stepEncoded
          )
        : eventBus.$emit("selectScriptItem", talkScriptId);
    });
    this.$store.state.enquete.isEnqueteOpen = false;
  }
  async created() {
    await this.initChatWindow();
    await this.convertAutoComplete(this.$store.state.yokuaru);
    this.updateInitData();
    this.setDevice(navigator.userAgent);
    this.setUserArgent(navigator.userAgent);
    this.initWindowOpen(this.isSP);
    eventBus.$off("changeRoute");
    eventBus.$on("changeRoute", ({ routes, index }) => {
      const previousIndex = this.index;
      //Dirty fix. Shold be fixed in the emitter.
      if (this.index === index && this.route === routes) {
        return;
      }

      this.route = routes;
      this.index = index;
      if (
        this.route &&
        this.route[this.route.length - 1].resourceName == "talkScript" &&
        this.route[this.route.length - 1].talkScriptType == "leaf" &&
        !("scenarioId" in this.route[this.route.length - 1]) &&
        index == routes.length - 1
      ) {
        this.showFeedback = true;
        if (this.$refs.Feedback) {
          this.$refs.Feedback.init();
        }
      } else if (
        this.route &&
        this.route[this.route.length - 1].resourceName == "scenario" &&
        this.route[this.route.length - 1].viewType == "result" 
        &&
        // !(
        //   this.route[this.route.length - 1].ancesters &&
        //   this.route[this.route.length - 1].ancesters.length > 0
        // ) &&
        index == routes.length - 1
      ) {
        this.showFeedback = true;
      } else {
        this.showFeedback = false;
      }

      if (index !== 0 && previousIndex <= index) {
        const item = this.route[index];
        const additionalTicketParams = {};
        if (this.inputMessage && this.inputMessage.length > 0) {
          additionalTicketParams.query = this.inputMessage;
        }

        if (item.viewType === "talkScript" && item.talkScriptType === "node") {
          additionalTicketParams.log_faq_channel = ["category"];
        }

        if (item.items) {
          additionalTicketParams.items = item.items;
        }
        if (item.viewType === "result") {
          additionalTicketParams.feedback = "none";
        }

        const ticketAction =
          item.viewType === "result"
            ? "answeredAction"
            : item.viewType === "scenario"
              ? "answeringAction"
              : "openAction";
        this.postTicket(ticketAction, additionalTicketParams);
      }
    });
    eventBus.$off("setScript");
    eventBus.$on("setScript", item => {
      if (
        this.routingMode != this.functionConst.DISPLAYING_ROUTE_MODE.URL_BASED
      ) {
        return;
      }
      const talkScriptId = item.talkScriptId
        ? item.talkScriptId
        : this.$route.params.talkScriptId;
      const result = {
        name: "urlWithParams",
        params: item.stepId
          ? {
              talkScriptId,
              stepEncoded: this.getStepEncodedFromRoute(item.stepId)
            }
          : { talkScriptId }
      };
      this.$router.push(result);
    });
    eventBus.$off("setResultScript");
    eventBus.$on("setResultScript", item => {});

    this.clearSuggestion();
  }

  @Watch("$route")
  onRouteChanged() {}
}
