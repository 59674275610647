import { PluginObject } from 'vue'
import { Component, Prop, Vue as _Vue } from 'vue-property-decorator'
import { TicketService } from "./ticket";
export const SAITicketService: PluginObject<any> = {
	installed: false,
	ticket: TicketService.getInstance(),
	url: "",
	productId: "",
	config: {},
	install(Vue: typeof _Vue, options?: any): void {
		if (this.installed) {
			return;
		}
		if (options && options.hasOwnProperty('config')) {
			this.config = options.config;
		}
		if (options && options.hasOwnProperty('url')) {
			this.url = options.url;
		}
		if (options && options.hasOwnProperty('productId')) {
			this.productId = options.productId;
		}
		this.installed = true;
		this.hoge = "asdf";
		this.ticket.setConfig(Object.assign(this.config, {"url": this.url, "productId": this.productId}))
		Vue.prototype.$ticket = this.ticket;
		// Object.defineProperties(Vue.prototype, {
		// 	$ticket: {
		// 		get() {
		// 			debugger
		// 			return this.ticket;
		// 		},
		// 	},
		// });
		// this.ticket.handleTicket({
		// 	type: "open",
		// })
	},
};
