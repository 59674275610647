import Vue from "vue";
import Component from "vue-class-component";

import { eventBus } from "@/eventBus";

const STATUS = {
  SEARCH_NO_SCRIPT: "searchNoScript",
  SEARCH_FAILED: "searchFailed"
};
@Component({
  props: {
    inputMessage: String,
    userInputChoices: Array,
    isSP: Boolean,
    userArgentData: Object,
    isIphone: Boolean,
    isYokuaru: Boolean,
    showFaqTitle: Boolean,
    routingMode: String
  }
})
export default class SuggestionMixin extends Vue {
  functionConst = CONSTS.FunctionConst;
  yokuaruTitle = "";
  choicesTitle = "";
  style = {};
  choicesNotFoundTitle = "";
  choicesSearchFailedTitle = "";
  choicesSearchFailedMessage = "";
  searchNoScript = "";
  searchFailed = "";
  hasUnresolvedEnquete = "";

  async selectAnswer(choice, key) {
    const talkScriptId = choice.id;
    eventBus.$emit("clearInputMessage");
    this.$emit("clearSuggestion");
    eventBus.$emit("selectScriptItem", talkScriptId);
    if (
      this.routingMode == this.functionConst.DISPLAYING_ROUTE_MODE.URL_BASED
    ) {
      this.$router.push({ name: "urlWithParams", params: { talkScriptId } });
    }
    this.close();
    const params = {
      query: this.inputMessage || "-",
      device: this.userArgentData.device.type || "PC",
      userId: this.$store.state.userId,
      osName: this.userArgentData.os.name,
      browserName: this.userArgentData.browser.name,
      log_faq_channel: this.isYokuaru ? ["frequent"] : ["search"],
      userId: this.$store.state.userId
    };
    if (choice.items) {
      params.items = choice.items;
    }
    if (this.inputMessage && this.inputMessage.length > 0) {
      params.query = this.inputMessage;
    }
    // const ticketData = {
    //   action: choice.scenario ? "answeringAction" : "answeredAction",
    //   params
    // };
    this.$ticket.setData(params);
    //this.$ticket.handleTicket(ticketData);
  }

  close() {
    this.$emit("close");
  }

  created() {
    eventBus.$on("suggestBottomUp", () => {
      this.suggestBottomUp();
    });
    eventBus.$on("suggestBottomDown", () => {
      this.suggestBottomDown();
    });
  }

  setConstMessage() {
    this.yokuaruTitle = this.$store.state.constObj.YOKUARU_TITLE;
    this.choicesTitle = this.$store.state.constObj.CHOICES_TITLE;
    this.otherChoicesLinkMessage = this.$store.state.constObj.OTHER_CHOICE_LINK_MESSAGE;
    this.scriptNotFoundResultsLinkMessage = this.$store.state.constObj.SCRIPT_NOT_FOUND_RESULTS_LINK_MESSAGE;
    this.scriptNotFoundResultsMessage = this.$store.state.constObj.SCRIPT_NOT_FOUND_RESULTS_MESSAGE;
    this.searchNoScript = STATUS.SEARCH_NO_SCRIPT;
    this.searchFailed = STATUS.SEARCH_FAILED;
    this.hasUnresolvedEnquete = this.$store.state.constObj.HAS_UNRESOLVED_ENQUETE;
  }
  mounted() {
    this.setConstMessage();
  }
  openEnquete(status) {
    this.$ticket.setData({
      status: status
    });
    this.$store.state.enquete.enqueteMode = status;

    const additionalTicketParams = {
      query: this.inputMessage || "-"
    };
    if (status === STATUS.SEARCH_FAILED) {
      this.postTicket("searchFailedAction", additionalTicketParams);
    }

    this.$store.state.enquete.isEnqueteOpen = true;
    this.$emit("updateIsFocusMessageInput", false);
  }
  async postTicket(action, params) {
    Object.assign(params, {
      device: this.userArgentData.device.type || "PC",
      userId: this.$store.state.userId,
      osName: this.userArgentData.os.name,
      browserName: this.userArgentData.browser.name
    });

    const ticketOrder = {
      action,
      params
    };
    //await this.$ticket.setData(params);
    this.$ticket.handleTicket(ticketOrder);
  }
}
