import Vue from "vue";
import VueScrollTo from "vue-scrollto";
import { SAISearchCategory } from "@sciseed/sai-search-category";
import { SAITicketService } from "@/libs/sai-ticket/dist/sai-ticket.umd.min.js";
import "@product/styles/sai-search-category.scss";
import { init, domReady } from "@";
import components from "@product/components";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ProgressBar from "vue-simple-progress";

const config = {
  container: "#saichat-content",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
};

const ticketConfig = {
  transitionTable: {
    open: {
      openAction: {
        status: "open"
      },
      searchNoScriptAction: {
        status: "searchNoScript"
      },
      searchFailedAction: {
        status: "searchFailed"
      },
      answeringAction: {
        status: "answering"
      },
      answeredAction: {
        status: "answered"
      },
      quitAction: {
        status: "quit",
        clear: true
      }
    },
    searchNoScript: {
      searchNoScriptAction: {
        status: "searchNoScript"
      },
      quitAction: {
        status: "quit",
        clear: true
      }
    },
    searchFailed: {
      searchFailedAction: {
        status: "searchFailed"
      },
      quitAction: {
        status: "quit",
        clear: true
      }
    },
    answering: {
      answeredAction: {
        status: "answered"
      },
      answeringAction: {
        status: "answering"
      },
      quitAction: {
        status: "answering",
        clear: true
      }
    },
    answered: {
      feedbackAction: {
        status: "feedbackDone"
      },
      quitAction: {
        status: "answered",
        clear: true
      }
    },
    feedbackDone: {
      enqueteAction: {
        status: "enqueteDone"
      },
      quitAction: {
        status: "feedbackDone",
        clear: true
      }
    },
    enqueteDone: {
      quitAction: {
        status: "enqueteDone",
        clear: true
      },
      quitAction: {
        status: "quit",
        clear: true
      }
    }
  },
  customItems: {
    log_tag_name_history: {
      type: "Array"
    },
    log_query_history: {
      type: "Array"
    }
  },
  ticketInterval: 1800
};

library.add(fas);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("progress-bar", ProgressBar);
Vue.use(SAITicketService, {
  config: ticketConfig,
  url: ENV_SETTINGS.ticketUrl,
  productId: ENV_SETTINGS.productId
});
Vue.use(SAISearchCategory);
Vue.use(VueScrollTo, config);
domReady(() => {
  init({ components, config });
});
