import Vue from "vue";
import Component from "vue-class-component";
import { eventBus } from "@/eventBus";
import { DomHandler } from "common/libs/domhandler";


@Component({
   props: {
    inputMessage: String,
    userArgentData: Object
  }
})
export default class ContentMixin extends Vue {
  eventHub = eventBus;

  get categoryWidth() {
    const constObj = this.$store.state.constObj;
    return Math.min(
      window.innerWidth * constObj.CATEGORY_WIDTH_MULTIPLE,
      constObj.MIN_CATEGORY_WIDTH
    );
  }
  get categoryHeight() {
    const constObj = this.$store.state.constObj;
    if (constObj.CATEGORY_HEIGHT !== 0) {
      return constObj.CATEGORY_HEIGHT;
    } else {
      return window.innerWidth <= 992
        ? window.innerHeight - constObj.CATEGORY_HEIGHT_SP_MINUS
        : window.innerHeight - constObj.CATEGORY_HEIGHT_MINUS;
    }
  }
  get scriptPackage() {
    return this.$store.state.resources;
  }

  selectAnswer(choice, key) {
    this.$emit("selectAnswer", choice, key);
  }
  scrollBottom() {
    if (this.getScrollIndex() === 0) {
      this.$emit("scrollTop");
    } else {
      const element = `#message${this.getScrollIndex()}`;
      this.$scrollTo(element);
    }
  }
  getScrollIndex() {
    const messagesIds = [];
    this.messages.filter(function(message, index) {
      if (message.from != "bot") {
        messagesIds.push(index);
      }
    });
    if (messagesIds.length > 0) {
      return messagesIds[messagesIds.length - 1];
    } else {
      return 0;
    }
  }
  async mounted() {

    const saichatContent = document.getElementById("saichat-content");
    if (saichatContent) {
      this.height = `${saichatContent.clientHeight - 10}px`;
    }
    if (this.isIphone) {
      DomHandler.preventOutsideScrolling(this.$refs.messagesContent);
    }
  }
}
