import "@babel/polyfill";
import "common/polyfill/ie11_nodelist_foreach";
import "whatwg-fetch";
if (window.fetch === undefined) {
  window.fetch = fetch;
}
import "es6-promise";
import "polyfill-queryselector";
import "scroll-behavior-polyfill";
import "core-js";
import "element-closest-polyfill";
import "main";
import "common/styles/initialize.scss";
