import _ from "underscore";
import { ScriptMatchingManager } from "@/libs/qaRetrieval";
import tinySegmenter from '@/libs/tinySegmenter' 

const ScriptMatchingManagerFactory = {
  _instance: null,
  getInstance(resources) {
    if (!this._instance) {
      this._instance = new ScriptMatchingManager({
        matchingScript: resources.script,
        invertedIndex: resources.inverted_index,
        synonymDict: resources.synonym_dict,
        invPostProbDist: resources.script_by_id,
        categoryData: resources.talk_script.body
      });
    }
    return this._instance;
  }
};

function getMapById(resources) {
  const talkScript = resources ? resources.talk_script.body : "";
  return _.indexBy(talkScript, item => {
    return item.id;
  });
}

function getSync(mapById, id) {
  return mapById && mapById[id];
}

function getSyncWithAncesters(mapById, id) {
  const target = getSync(mapById, id);
  if (target && !target.ancesters) {
    target.ancesters = [];
    for (
      let current = getSync(mapById, target.parent);
      current;
      current = getSync(mapById, current.parent)
    ) {
      target.ancesters.unshift(current);
    }
  }
  return target;
}

function cleanText(text) {
  return (
    text &&
    text
      .replace(/[Ａ-Ｚａ-ｚ０-９]/g, s => {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
      })
      .toLowerCase()
  );
}

function getChoicesItems(text, resources) {
  const scriptMatchingManager = ScriptMatchingManagerFactory.getInstance(resources);
  const ids = scriptMatchingManager.getSearchResult(cleanText(text));
  const mapById = getMapById(resources);
  const items = ids
    .map(id => {
      return getSyncWithAncesters(mapById, id);
    })
    .filter(item => item);

  return items;
}

function containResetTriggerMessages(text, triggerMessages) {
  return _.contains(triggerMessages, text);
}

function searchRepresentativeWord(synonymDict, sortedKeys, text) {
  let result = "";
  const keys = sortedKeys//||Object.keys(synonymDict)
  for (let key of keys) {
    if (text.toLowerCase() == key.toLowerCase()) {
      result = synonymDict[key];
      break;
    }
  }
  return result;
}

function createVocabularyList(synonymDict, sortedKeys , wordList) {
  const result = [];
  wordList.forEach(element => {
    if (element) {
      const representativeWord = searchRepresentativeWord(synonymDict, sortedKeys, element);
      if (representativeWord) result.push(representativeWord);
    }
  });
  return result;
}

export default {
  mutations: {
    setSearchingMessage(state, payload) {
      state.searchingMessage = payload.searchingMessage;
    }
  },
  getters: {

  },
  actions: {
    searchAutoComplete({ commit, getters, dispatch, rootState }, text) {
      const items = getChoicesItems(
        text,
        rootState.resources
      );
      //this.$ticket.setData({ query: items })
      // commit("setSearchingMessage", { searchingMessage: text });//, { root: true });
      // commit("setSearchResults", items);
      return items;
    },
    convertAutoComplete({ commit, getters, dispatch, rootState }, items) {
      const mapById = getMapById(rootState.resources);
      const res = items
        .map(item => {
          return getSyncWithAncesters(mapById, item.id);
        })
        .filter(item => item);
      return res;
    },
    searchSynonym({ commit, getters, dispatch, rootState }, text) {
      // 応急処置で「 」「.」「/」追加(タイトルに各種ある時の不具合対応) 
      let inputWordList = tinySegmenter.segment(text).filter(w=>
         !w.match(/^[ ./0-9a-zA-Z\u30a0-\u30ff\u3040-\u309f\u3005-\u3006]$/)
      )
      //let inputWordList = text.split(/\s+/);
      const synonymDict = ScriptMatchingManagerFactory.getInstance().getScriptData().synonymDict;
      const sortedSynonymKeys = ScriptMatchingManagerFactory.getInstance().getScriptData().sortedSynonymKeys;

      const vocabularyList = createVocabularyList(synonymDict, sortedSynonymKeys, inputWordList);

      vocabularyList.forEach(element => {
        const toUpperCaseElement = element.toUpperCase();
        for (let key of Object.keys(synonymDict)) {
          if (toUpperCaseElement == synonymDict[key].toUpperCase())
            inputWordList.push(key);
        }
      });
      const result = inputWordList
        .filter(function(x, i, self) {
          return self.indexOf(x) === i;
        })
        .filter(v => v);
      return result;
    },
 
    isGreetingMessages({ commit, state, rootState, dispatch }, text) {
      const greetKeys = Object.keys(rootState.constObj.GREETING_CHAT);
      return _.contains(greetKeys, text);
    },
    searchGreetingMessage({ commit, getters, dispatch, rootState }, text) {
      const answers = rootState.constObj.GREETING_CHAT[text];
      const index = Math.floor(Math.random() * answers.length);
      return answers[index];
    },
    searchMessage({ commit, getters, dispatch, rootState }, text) {
      if (!text) return;

      if (
        containResetTriggerMessages(
          text,
          rootState.constObj.RESET_TRIGGER_MESSAGES
        )
      ) {
        dispatch("resetMessages", null, { root: true });
        return;
      }
      if (!this.getters.searchEnabled) {
        dispatch("addInterruptionMessage", null, { root: true });
        return;
      }

      const searchResult = getChoicesItems(
        text,
        rootState.resources
      );

      const searchResultItems = {
        results: searchResult.map(e => e.items.scenario_id),
        query: text
      };

      //commit("setSearchResults", searchResult);
      dispatch("updateTicketItems", searchResultItems);
      dispatch("addSearchResultMessage", searchResult);
    }
  }
};
