import axios from "axios";
// interface TicketData {
// 	startTime: string;
// 	endTime?: string;
// 	item?: any;
// }

export interface TicketServiceInterface {
	setItems(items: any): void;
	setUserId(text: string): void;
	setStartTime(time?: string): void;
	setEndTime(time?: string): void;
	setData(data: any): void;
	setStatus(status: string): void;
	post(): void;
	reset(): void;
}
interface TicketData{
	[key:string]:string|number|Array<string>
}
class Ticket {
	private items: any;
	private startTime: string = "";
	private endTime: string = "";
	private data: TicketData = {}; 
	private status: string = "open";
	private userId: string = "";
	constructor(private partitionKey: string, private rangeKey: number) {

	}
	public set Items(items: any) {
		this.items = items;
	}
	public set Status(status: string) {
		this.status = status;
	}
	public set UserId(userId: string) {
		this.userId = userId;
	}
	public set StartTime(time: string) {
		this.startTime = time
	}
	public set EndTime(time: string) {
		this.endTime = time
	}

	public set Data(data: TicketData) {
		for (const key in data) {
			if (key === 'items') {
				continue;
			}
			this.data[key] = data[key];
		}
		if (data.items) {
			this.Items = data.items;
		}
	}
	public get PartitionKey(){
		return this.partitionKey;
	}
	public get RangeKey(){
		return this.rangeKey;
	}
	public get Items(){
		return this.items;
	}
	public get UserId(){
		return this.userId;
	}
	public get Status(){
		return this.status;
	}
	public get StartTime(){
		return this.startTime;
	}
	public get EndTime(){
		return this.endTime;
	}

	public get Data() {
		const values:TicketData = {};
		Object.assign(values, { status: this.status, userId: this.userId,});
		if (this.data != null) {
			Object.assign(values, this.data);
		}
		if (this.items != null) {
			Object.assign(values, this.items);
		}
		if (this.startTime != '') {
			Object.assign(values, { startTime: this.startTime });
		}
		if (this.endTime != '') {
			Object.assign(values, { endTime: this.endTime });
		}
		return values;
	}
}
interface TicketConfig{
	url:string, 
	productId: string, 
	transitionTable: any, 
	customItems: any, 
	ticketInterval: number
}

export class TicketService implements TicketServiceInterface {
	private static readonly ticketService:TicketService = new TicketService();
	private ticket: Ticket | null = null;
	private config: TicketConfig | null = null; 
	private readonly STATUS_OPEN = "open"
	private readonly DEFAULT_TICKET_INTERVAL: number = 1800
	//private readonly VALUE_NOT_SET = "-"

	private constructor() { 
	}
	public static getInstance(): TicketService{
		return this.ticketService;
	}
	public setConfig(config: TicketConfig): void{
		this.config = config
	}
	public async ticketrequest(): Promise<{ error: boolean, response: any }> {
		if (!this.config) {
			return  { error: true, response: "ERROR_CONFIG_NOT_EXIST"};
		}

		if (this.ticket !== null) {
			return { error: true, response: "ERROR_TICKET_ALREADY_EXIST"};
		}

		const values = {};
		try {
			const res: any = await axios({
				url: this.config.url,
				headers: {
					'Content-Type': 'application/json'
				},
				method: 'POST',
				data: { values },
			});

			const data = res.data;
			if (data && data.hasOwnProperty('putItem') && data.putItem.hasOwnProperty('partitionKey') && data.putItem.hasOwnProperty('rangeKey')) {
				const { partitionKey, rangeKey } = data.putItem;
				const ticket = new Ticket(partitionKey, rangeKey);
				this.ticket = ticket;
			}
			return { error: false, response: this.ticket };
		} catch (e) {
			return { error: true, response: e };
		}
	}
	public async setItems(item: any) {
		if (this.ticket === null) {
			await this.ticketrequest();
		}
		if (this.ticket) {
			this.ticket.Items = item;
		}
	}
	public async setStatus(status: string) {
		if (this.ticket === null) {
			await this.ticketrequest();
		}
		if (this.ticket) {
			this.ticket.Status = status;
		}
	}
	public async setUserId(userId: string) {
		if (this.ticket === null) {
			await this.ticketrequest();
		}
		if (this.ticket) {
			this.ticket.UserId = userId;
		}
	}
	public async setStartTime(time?: string) {
		if (this.ticket === null) {
			await this.ticketrequest();
		}
		if (this.ticket) {
			this.ticket.StartTime = time || String(new Date().getTime());
		}
	}
	public async setEndTime(time?: string) {
		if (this.ticket === null) {
			await this.ticketrequest();
		}
		if (this.ticket) {
			this.ticket.EndTime = time || String(new Date().getTime());
		}
	}
	public async setEndTimeAsCurrentTime() {
		if (this.ticket === null) {
			await this.ticketrequest();
		}
		if (this.ticket) {
			const endTime = String(new Date().getTime());
			this.ticket.EndTime = endTime;
		}
	}
	public async setStartTimeAsCurrentTime() {
		if (this.ticket === null) {
			await this.ticketrequest();
		}
		if (this.ticket) {
			const startTime = String(new Date().getTime());
			this.ticket.StartTime = startTime;
		}
	}
	public async setData(data: TicketData) {
		if (this.ticket === null) {
			await this.ticketrequest();
		}
		if (this.ticket) {
			this.ticket.Data = data;
		}
	}
	public async open() {
		if (this.ticket === null) {
			await this.ticketrequest();
		}
		if (this.ticket) {
			this.setStartTimeAsCurrentTime();
			this.setStatus(this.STATUS_OPEN)
		}
	}

	public async post() {

		if (!this.config) {
			return;
		}
		if (this.ticket == null) {
			return;
		}
		const postData = this.ticket.Data;
		const values: any = { partitionKey: this.ticket.PartitionKey, rangeKey: this.ticket.RangeKey };		
		for(const [key, value] of Object.entries(postData)){
			const newKey = key.split(/(?=[A-Z])/).join('_').toLowerCase();
			values[newKey] = value;
		}
		const res: any = await axios({
			url: this.config.url,
			headers: {
				'Content-Type': 'application/json'
			},
			method: 'POST',
			data: { values },
		});
		return res;
	}
	public reset() {
		this.ticket = null;
	}

	public async handleTicket(payload: any){
		if (!this.config) {
			return;
		}

		const action: string = payload.action
		if (!this.keepTicketCondition(action)){
			await this.refreshTicket();
		}

		const currentStatus: string = this.ticket && this.ticket.Status ? this.ticket.Status : this.STATUS_OPEN
		//const  this.config && this.config.transitionTable
		const transitionConfigration = this.config.transitionTable[currentStatus][action]
		if (!transitionConfigration){
			//throw "Invalid ticket configuration"
			return;
		}

		if (transitionConfigration.status){
			this.setStatus(transitionConfigration.status)
		}
		if (payload.userId){
			this.setUserId(payload.userId)
		}

		//let dataToBeAdded = {}
		//Object.assign(dataToBeAdded, payload.params||{})
		const newTicketData = this.generateTicketData(payload.params||{})
		await this.setData(newTicketData)
		const endTime = new Date().getTime()
		this.setEndTime(String(endTime))

		
		if(!transitionConfigration.nopost && transitionConfigration.clear){
			await this.post()
			this.reset()
		}else if(!transitionConfigration.nopost){
			this.post()
		}else if(transitionConfigration.clear){
			this.reset()
		}
	}

	private keepTicketCondition(action: string): boolean{
		if (!this.config) {
			return false;
		}
		const ticketInterval = this.config.ticketInterval||this.DEFAULT_TICKET_INTERVAL;
				//const ticketInterval = this.config?.ticketInterval||this.DEFAULT_TICKET_INTERVAL
	
		const withinTermCondition = this.withinTerm(ticketInterval);
		if (!withinTermCondition){
			return false
		}

		if (!this.ticket) {
			return false;
		}
		const currentStatus = this.ticket.Status
		if (currentStatus && 
			this.config.transitionTable && 
			this.config.transitionTable[currentStatus] && 
			this.config.transitionTable[currentStatus][action]
		){
			return true;
		}
		return false;
	}

	private async refreshTicket(){
		this.reset()
		await this.ticketrequest()
		await this.open()
	}
	private withinTerm(interval: number) {
	  if (this.ticket && this.ticket.StartTime) {
	    return (new Date().getTime() - Number(this.ticket.StartTime)) / 1000 < interval;
	  } else {
	    return false;
	  }
	}
	private generateTicketData(data: TicketData): TicketData{
		if (!this.config) {
			return {};
		}
		const newData: TicketData = {}
		for(const [key, value] of Object.entries(data)) {
			if(this.config.customItems && this.config.customItems[key] && this.config.customItems[key].type === "Array"){
				
				if(typeof value!=='string'){
					continue
				}

				if (!this.ticket || !this.ticket.Data[key]){
					newData[key] = [value];
					continue
				}
				
				const currentTicketData = this.ticket.Data[key];
				newData[key] = Array.isArray(currentTicketData) ?  currentTicketData.concat([value]) : [value]
			
			}if(this.config.customItems && this.config.customItems[key] && this.config.customItems[key].type === "Object"){
				newData[key] = Object.assign(newData[key]||{}, data[key])
			}else{
				newData[key] = data[key]
			}
		}
		return newData
	}
}
